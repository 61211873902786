
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.width {
    width: fit-content;
}

.list-item {
    @include lg {
        width: 28%;
    }
}
